<template>
  <div class="home-page">
    <Banner />
    <div class="item-1">
      <div class="h2 mg-b-8">助力商户</div>
      <div class="tac fs-16 pd-b-50">服务全行业，携手发展提升商户品牌价值</div>
      <div class="mg-b-50 flex flex-x-between mg-x-auto" style="width: 1080px">
        <div class="flex w-320">
          <img src="/img/home/item_1_4.png" class="w-320 h-210" />
        </div>
        <div class="flex flex-y-center flex-x-between">
          <div>
            <div class="flex">
              <div class="flex flex-y-center flex-item-1 mg-r-20">
                <div class="w-70 h-64 p-rel">
                  <div class="w-70 h-64 br-6 flex p-abs" style="background: #dd2c34; transform: skew(-6deg)"></div>
                  <div class="p-abs w-full h-full flex flex-y-center flex-x-center">
                    <img src="/img/home/item_1_px.png" class="w-46 h-46" />
                  </div>
                </div>
                <div class="mg-l-20 flex-item-1">
                  <div class="fs-22">商户培训</div>
                  <div class="w-260 pd-t-6">帮助销售人员了解产品，熟练操作，提升销售话术及产能</div>
                </div>
              </div>
              <div class="flex flex-y-center flex-item-1">
                <div class="w-70 h-64 p-rel">
                  <div class="w-70 h-64 br-6 flex p-abs" style="background: #dd2c34; transform: skew(-6deg)"></div>
                  <div class="p-abs w-full h-full flex flex-y-center flex-x-center">
                    <img src="/img/home/item_1_jrrk.png" class="w-46 h-46" />
                  </div>
                </div>
                <div class="mg-l-20 flex-item-1">
                  <div class="fs-22">金融入口</div>
                  <div class="w-260 pd-t-6">帮助商户在银联云闪付及银行APP等入口上获得金融级流量，并转化为门店销量</div>
                </div>
              </div>
            </div>
            <div class="flex mg-t-40">
              <div class="flex flex-y-center flex-item-1 mg-r-20">
                <div class="w-70 h-64 p-rel">
                  <div class="w-70 h-64 br-6 flex p-abs" style="background: #dd2c34; transform: skew(-6deg)"></div>
                  <div class="p-abs w-full h-full flex flex-y-center flex-x-center">
                    <img src="/img/home/item_1_zzfw.png" class="w-46 h-46" />
                  </div>
                </div>
                <div class="mg-l-20 flex-item-1">
                  <div class="fs-22">增值服务</div>
                  <div class="w-260 pd-t-6">依托银联核心清算系统，为商户提供一站式平台管理及商城服务</div>
                </div>
              </div>
              <div class="flex flex-y-center flex-item-1">
                <div class="w-70 h-64 p-rel">
                  <div class="w-70 h-64 br-6 flex p-abs" style="background: #dd2c34; transform: skew(-6deg)"></div>
                  <div class="p-abs w-full h-full flex flex-y-center flex-x-center">
                    <img src="/img/home/item_1_yxzc.png" class="w-46 h-46" />
                  </div>
                </div>
                <div class="mg-l-20 flex-item-1">
                  <div class="fs-22">营销支持</div>
                  <div class="w-260 pd-t-6">整合银联及银行营销资源，帮助商户带来如满减、抢购等营销活动</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="h-325 p-rel">
        <div class="grey p-abs" style="top: 38px"></div>
        <div class="e-width flex p-abs h-full" style="left: 50%; transform: translateX(-50%)">
          <div class="flex-item-1 flex flex-y-center">
            <div>
              <div class="fs-22 pd-b-16">助力商户经营</div>
              <div class="pd-b-4 fs-16">增加商户竞争力</div>
              <div class="fs-16">促进转化、提高成交</div>
            </div>
          </div>
          <div class="flex-item-1 mg-r-20 border pd-10 bg-fff">
            <img src="/img/home/item_1_1.jpg" class="w-244 h-162" alt />
            <div class="fs-16 tac pd-t-20">
              <div class="pd-b-10"><strong>提升成交转化率</strong></div>
              <div>降低销售门槛</div>
              <div>促进客户下单</div>
            </div>
          </div>
          <div class="flex-item-1 mg-r-20 border pd-10 bg-fff">
            <img src="/img/home/item_1_2.jpg" class="w-244 h-162" alt />
            <div class="fs-16 tac pd-t-20">
              <div class="pd-b-10"><strong>提升平均客单价</strong></div>
              <div>客户消费升级</div>
              <div>挖掘客户价值</div>
            </div>

          </div>
          <div class="flex-item-1 border pd-10 bg-fff">
            <img src="/img/home/item_1_3.jpg" class="w-244 h-162" alt />
            <div class="fs-16 tac pd-t-20">
              <div class="pd-b-10"><strong>提升客户进店量</strong></div>
              <div>全新营销主题</div>
              <div>吸引更多客流</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="item-2 h-500 p-rel">
      <div class="p-abs e-width" style="z-index:10;left:50%;transform: translateX(-50%)">
        <div class="h2 pd-t-66 pd-b-16">产品介绍</div>
        <div class="tac fs-16">用科技+金融，打造领先的场景分期生态圈，我们的产品服务商户20000+，涵盖通讯产品、3C数码、家具家电等行业</div>
        <div class="item3 pd-t-50">
          <div class="flex tac" style="border-radius: 8px; overflow: hidden;">
            <div class="flex-item-1 h-200 flex flex-y-center flex-x-center pro-item">
              <div>
                <!-- <img src="/img/home_syt.png" alt /> -->
                <div class="text flex flex-x-center flex-y-center">
                  <div>
                    <div class="flex flex-x-center flex-y-center">
                      支持银联信用卡、花呗、白条分期支付
                      支持云闪付、支付宝、微信聚合全款支付 支持线上、线下全场景使用
                    </div>
                    <div class="fs-18 pd-t-10">全能收银台</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-item-1 h-200 flex flex-y-center flex-x-center pro-item">
              <div>
                <!-- <img src="/img/home_xssc.png" alt /> -->
                <div class="text flex flex-x-center flex-y-center">
                  <div>
                    <div class="flex flex-x-center flex-y-center">
                      统一商城管理平台，一次输入管理 接入金融千万级多流量入口
                      商城全面支持分期，全款交易
                    </div>
                    <div class="fs-18 pd-t-10">智能线上商城</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex-item-1 h-200 flex flex-y-center flex-x-center pro-item">
              <div>
                <!-- <img src="/img/home_yxgl.png" alt /> -->
                <div class="text flex flex-x-center flex-y-center">
                  <div>
                    <div class="flex flex-x-center flex-y-center">
                      深度嵌入商户营销和业务流程，可定制 业务办理操作流程统一、培训简单
                      标准化信息管理方式，降低运营成本
                    </div>
                    <div class="fs-18 pd-t-10">智慧营销管理</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <video class="video" loop muted autoplay src="/img/home/item_2_bg.mp4"></video>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/banner";
export default {
  name: "Home",
  components: { Banner },
  data() {
    return {
      window: window,
    };
  },
};
</script>
<style lang="scss" scoped>
.home-page {
  .video {
    height: 100%;
    margin: 0px;
    object-fit: cover;
    padding: 0px;
    position: absolute;
    width: 100%;
  }
  .h2 {
    text-align: center;
    font-weight: 400;
    font-size: 34px;
    line-height: 56px;
    margin-top: 0;
    letter-spacing: 2px;
    color: #222222;
  }
  .item-1 {
    background: #fff;
    padding: 50px 0;
    .grey {
      background: #f2f2f2;
      width: 100%;
      height: 250px;
    }

    .border {
      border: 1px solid #e3e3e3;
    }
  }
  .item3 {
    .cp:hover {
      text-decoration: underline;
    }
    .pro-item {
      position: relative;
      overflow: hidden;
      cursor: pointer;
      // &:hover .text {
      //   bottom: 0;
      // }
      .text {
        width: 100%;
        background: rgba($color: #000000, $alpha: 0.5);
        left: 0;
        color: #fff;
        position: absolute;
        // bottom: -200px;
        bottom: 0;
        font-size: 14px;
        padding: 0 60px;
        height: 100%;
        transition: all 0.3s;
      }
    }
  }
}
</style>
