<template>
  <!-- 轮播 -->
  <el-carousel
    trigger="click"
    :height="bannerHeight"
    :indicator-position="bannerList.length==1?'none':''"
    :arrow="bannerList.length==1?'never':'never'"
    :interval="5000"
    v-if="bannerList.length>0"
  >
    <el-carousel-item v-for="(item,key) in bannerList" :key="key" @click.native="open(item.link)">
      <div ref="bannerContent" class="banner-content cp pos-rel per-h-100">
        <img ref="bann" v-if="!item.imageUrl" class="pos-abs" src="/img/banner1.jpg" alt />
        <img v-else class="pos-abs" :src="item.imageUrl" alt />
        <!-- 体验按钮 -->
        <slot></slot>
        <div class="pos-abs texts flex flex-y-center" :style="{height:bannerHeight}">
          <div :style="{maxHeight:bannerHeight,width:'100%'}">
            <div class="per-w-100 ql-snow ql-editor" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </el-carousel-item>
  </el-carousel>
</template>

<script>
import { floor } from 'lodash'
export default {
  props: {
    linkType: {
      type: Number,
      default: 2
    },
    height: {
      type: Number,
      default: 500
    }
  },
  computed: {

  },
  data() {
    return {
      bannerList: [{imageUrl:'/img/banner.jpg'}],
      bannerHeight: this.height + 'px'
    }
  },
  created() {
    this.bannerHeight = this.getbannerHeight()
  },
  mounted() {
    window.onresize = function temp() {
      this.bannerHeight = this.getbannerHeight()
    }.bind(this)
  },
  methods: {
    getbannerHeight() {
      let _w = document.documentElement.clientWidth || document.body.clientWidth;
      if (_w > 1080) {
        let ss = floor(_w * this.height / 1920)
        return ss + 'px'
      } else {
        let ss = floor(1080 * this.height / 1920)
        return ss + 'px'
      }
    },
    open(link) {
      if (!link) {
        return
      }
      window.open(link)
    }
  }
}
</script>
<style lang="scss" scoped>
.banner-content {
  width: 100%;
  // height: 500px;
  position: relative;
  img {
    width: 100%;
    // height: 100%;
  }
  .texts {
    width: 1200px;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    .text-item {
      position: relative;
      padding-left: 16px;
      &:before {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #e5e5e5;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>